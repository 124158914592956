import(/* webpackMode: "eager" */ "/vercel/path0/components/AppProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/banners/HolidayBanner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/scripts/chatwoot/ChatwootFallback.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/scripts/chatwoot/ChatwootWidget.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/scripts/FacebookPixel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/scripts/GoogleAnalytics4.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/CookiesNotification.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/TopLoader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"font\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/global.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/critical.scss")